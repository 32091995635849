import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
import styles from "../styles/services.css";
import Footer from "./Footer";
import familyLetter from "../../img/uploads/family-letter.pdf";
import calendar from "../../img/uploads/event-cal.pdf";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import test from '../images/test.png';
import jsonData from "./events.json"

export const query = graphql`
  query news {
    ourServicesGatsby: file(relativePath: { eq: "newsPage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      our_services_title_deluxe
      our_services_left_paragraph
      our_services_right_paragraph
      our_services_left_title
      monthly_calendar 
    }
  }
`;

function News({ data }) {
  const ourServicesGatsby = getImage(data.ourServicesGatsby);

  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">
          News and Events
        </h1>
      </div>

      <section className="main-box news-box">
        <div className="main-about-us-cnt news-width">
          <div className="text-card news-txt-card">
            <div className="news-ctn">
            <h3 className="who-are-we news-title">Monthly Calendar</h3>
                <a href={calendar}>
                <StaticImage
                    src="../../img/uploads/event-cal.png"
                />
                </a>
            </div>
            <div className="news-ctn">
                <h3 className="who-are-we news-title">Family Letter</h3>
                <a href={familyLetter}>
                <StaticImage
                    src="../../img/uploads/family-letter.png"
                />
                </a>
            </div>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt news-width">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default News;
